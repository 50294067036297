import * as pwMap from './shared/map.js';
import {bisector} from 'd3-array';
import {getGlobalGribIndexEntry, pwLookupGlobalGribKey} from './globalGrib.js';
import {formatTimestampLong, formatTimezoneShort, pad2, PWGMap_addInitListener, PWGMap_impl} from './shared/routing.js';
import {isSmartPhone, isSmartPhoneBrowser} from './platform.js';
import toggleFullScreen from './map-full-screen.js';

const gettext = window.gettext,
    copied_text = gettext('Copied!'),
    copy_to_clipboard_text = gettext('Copy to clipboard');

const
    GLOBAL_continuousTimeStep = 60 * 60,
    GLOBAL_continuousUpdateTime = 100,
    DEFAULT_TIME_MINIMUM = 0,
    DEFAULT_TIME_MAXIMUM = 1,
    boatMarkerAnchorX = 5,
    boatMarkerAnchorY = 5,
    boatMarkerWidth = 10,
    boatMarkerHeight = 10,
    blogPostMarkerAnchorX = 3,
    blogPostMarkerAnchorY = 3,
    blogPostMarkerWidth = 6,
    blogPostMarkerHeight = 6,
    canGoLive = false,
    isContinuous = false,
    trackDataLoadedCallbacks = jQuery.Callbacks("once memory"),
    blogFeedLoadedCallbacks = jQuery.Callbacks("once memory"),
    context = $j('#track-display-js').data("context");
let timeMinimum = DEFAULT_TIME_MINIMUM,
    timeMaximum = DEFAULT_TIME_MAXIMUM,
    boatMarker = null,
    isLive = false,
    onLive,
    onRewind,
    gotFirstBoatPosition = false,
    pointerDownPos = null;

if (window.defaultZoom === undefined) {
    window.defaultZoom = 11;
}



function d2(n) {
    return (n < 10 ? '0' : '') + n;
}

function formatTime(timestamp, utcOffset) {
    const
        date = new Date((timestamp + utcOffset * 3600) * 1000),
        Y = date.getUTCFullYear(),
        m = d2(date.getUTCMonth() + 1),
        d = d2(date.getUTCDate()),
        H = d2(date.getUTCHours()),
        M = d2(date.getUTCMinutes());
    return Y + m + d + '-' + H + M;
}

function formatLongTime(timestamp, utcOffset) {
    const
        date = new Date((timestamp + utcOffset * 3600) * 1000),
        month = window.GET_MONTH_NAME(date.getUTCMonth()),
        day = date.getUTCDate();
    return month + ' ' + day + ', ' + d2(date.getUTCHours()) + ':' + d2(date.getUTCMinutes());
}

const
    UPDATE_TIMEOUT = context["UPDATE_TIMEOUT"] || (context["displayAsHeader"] ? 60000 * 10 : 60000),
    boatMarkerClassName = 'gmap-marker-route1',
    blogPostMarkerClassName = 'gmap-marker-route2',
    preload = {},
    google_styles = [
        {
            featureType: 'poi',
            stylers: [{visibility: 'off'}]
        },
        {
            featureType: 'landscape',
            stylers: [{visibility: 'off'}]
        },
        {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{visibility: 'off'}]
        },
        {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [{visibility: 'off'}]
        }
    ];
let currentRouteIndex = -1,
    previousRouteIndex = null,
    previousImageIndex = null,
    trackData = {route: [], images: []},
    animationHandle = null,
    showImageHandle = null,
    isPlaying = false,
    trackDuration = 0;

$j('[name=trackDuration]').on('change', function () {
    PWGMap.removeItem(trackGroup);
    pwMap.LayerGroup.eachItem(trackGroup, function (item) {
        pwMap.LayerGroup.removeItem(trackGroup, item);
        item.dispose?.();
    });
    updateTrack(trackData);
});

function clipRoute(route) {
    if (!trackDuration) {
        return route;
    }

    let ri = route.length - 1;
    while (ri > 0 && route[route.length - 1]['t'] - route[ri]['t'] < trackDuration) {
        ri--;
    }

    return route.slice(ri);
}

function setValues(values) {
    const dataHubText = gettext("Only available with DataHub"),
        mobileDataHubText = gettext("DataHub Required");
    if (!context['trackDisplayId'] || context['trackDisplayId'] === 'GenericBoatNameClass') {
        values = null;
    }
    if (values) {
        $j('.track-update-details-name').text(context['boatName']);
        $j('.track-update-details-boat-speed').text((values.bsp * 0.54).toFixed(1) + ' kts');
        $j('.track-update-details-last-update').text(
            'trackUtcOffset' in context ? formatTimestampLong(values.t, context.trackUtcOffset)
                : new Date(values.t * 1000).toUTCString()
        );
        $j('.track-update-details-coords').html(pwMap.utils.formatLatitude(values.p.lat) + '<br>' + pwMap.utils.formatLongitude(values.p.lon));
        if (context['hasDataHub']) {
            $j('.track-update-details-twd').removeClass('unpopulated').addClass('populated');
            if (values.twd) {
                $j('.track-update-details-twd-direction').css({'transform': 'rotate(' + (135 + values.twd) + 'deg)'});
            }
        } else {
            $j('.track-update-details-twd').removeClass('populated').addClass('unpopulated');
        }
        $j('.track-update-details-twd-text').text(
            context['hasDataHub'] ? values.twd ? Math.round(values.twd) + '°' : '-' : isSmartPhone || isSmartPhoneBrowser ? mobileDataHubText : dataHubText
        );
        $j('.track-update-details-tws').text(
            context['hasDataHub'] ? values.tws ? (values.tws / 0.5144).toFixed(1) + ' kts' : '-' : ''
        );
    }
    $j('.track-details-share-button-wrapper').toggleClass('display-none', !values);
    if (isSmartPhoneBrowser || isSmartPhone) {
        $j('.blog-post-wrapper').innerHeight($j('.track-update-details').innerHeight());
    }
}

function imageUrlFromTime(timestamp) {
    return context["TRACKING_DATA_PREFIX_URL"] + '-' + formatTime(timestamp, context["trackUtcOffset"]) + '.jpg';
}

window.showImage = function showImage(index) {
    if (index > trackData.images.length) {
        return;
    }
    const requestIndex = index;
    let t = trackData.images[index],
        k = '_' + t;
    while (index >= 0 && typeof preload[k] == 'object' && preload[k] !== null) {
        if (index === 0) {
            index = requestIndex;
            t = trackData.images[index];
            k = '_' + t;
            break;
        }
        index--;
        t = trackData.images[index];
        k = '_' + t;
    }

    const imageUrl = imageUrlFromTime(t);
    let el = $j('<img>', {src: imageUrl});
    if (context["fullscreen"]) {
        el = $j('<a>', {href: imageUrl, target: "_blank"}).append(el);
    }
    $j('#imageStream').empty().append(el);

    if (showImageHandle) {
        clearTimeout(showImageHandle);
        showImageHandle = null;
    }
    if (requestIndex != index) {
        showImageHandle = setTimeout(function () {
            showImageHandle = null;
            showImage(requestIndex);
        }, 100);
    }
};

// Arrange to show spinner while track or blog data requests are in flight.
let spinnerValue = 0;

function showSpinner() {
    spinnerValue++;
    $j('#id_spinnerFrame').show();
}

function hideSpinner() {
    if (spinnerValue) {
        spinnerValue--;
    }
    if (!spinnerValue) {
        setTimeout(function () {
            if (!spinnerValue) {
                $j('#id_spinnerFrame').hide();
            }
        }, 300);
    }
}

const boatMarkerObject = {};
let pollTimeout = null,
    formatsObject = {},
    trackGroup = null;

function poll(recentreMap) {
    console.log('Polling', new Date);
    if (!$j('.modal').length) {
        if (trackGroup) {
            PWGMap.removeItem(trackGroup);
        } else {
            trackGroup = pwMap.layerGroup();
        }
        setValues(null);
        let liveRequestCount = 0;
        // Is this the tracking display for one boat?
        // (note: intentionally doesn't apply to rallies with one or fewer members)
        const
            singular = (
                !context["trackDisplayIds"]
                && !!context["trackDisplayId"]
                && context["trackDisplayId"] !== "GenericBoatNameClass"),
            uniqueNames = singular ? [context["trackDisplayId"]] : context["trackDisplayIds"] || [];
        uniqueNames.forEach(function (trackDisplayId) {
            const endTime = context.endTimes && context.endTimes[trackDisplayId];
            if (endTime && endTime < Date.now() / 1000) {
                // Do nothing for this boat... don't load track, and don't show in legend.
                return;
            }
            if (!singular && !formatsObject[trackDisplayId]) {
                formatsObject[trackDisplayId] = {
                    destination: {
                        lon: context['cruisingRallyDestLon'],
                        lat: context['cruisingRallyDestLat']
                    },
                    uName: trackDisplayId,
                    name: context['boatNames'] && context['boatNames'][trackDisplayId] || trackDisplayId,
                    boatType: context['boatTypes'] && context['boatTypes'][trackDisplayId],
                    color: (context['boatColours'] && context['boatColours'][trackDisplayId]
                        || PWGMap.boatColour(trackDisplayId)),
                    thickness: 1,
                    opacity: 1.0,
                    lineStyle: 'solid',
                    clickHandler: function () {
                        $j('.track-display-right-column').removeClass('track-display-right-column-open');
                        const marker = boatMarkerObject[trackDisplayId];
                        if (marker) {
                            PWGMap.setZoom(13);
                            PWGMap.setCentre({lat: marker.lat, lon: marker.lon});
                            if (PWGMap.map1._googleMap) {
                                // Note that maps.js code that routes google map events expects an
                                // event with a latLng property
                                google.maps.event.trigger(marker, 'click', {latLng: marker.position});
                            } else {
                                marker.click();
                            }
                        }
                    }
                };
            }
            let ajaxUrl;
            if (context['trackJsonURL']) {
                ajaxUrl = context['trackJsonURL'];
            } else {
                ajaxUrl = (
                    context["TRACKING_DATA_BASE_URL"]
                    + trackDisplayId
                    + ".json");
            }
            liveRequestCount += 1;
            $j.ajax({
                url: ajaxUrl,
                dataType: "json",
                success: function (data) {
                    if (singular) {
                        updateTrack(data);
                    } else {
                        data["uniqueName"] = trackDisplayId;
                        formatsObject[trackDisplayId].route = data.route;
                        formatsObject[trackDisplayId].lastUpdate = updateTrack(data);
                    }

                    blogFeedLoadedCallbacks.add(function (posts) {
                        if (singular) {
                            plotBlogPosts(posts);
                        }
                    });
                },
                statusCode: {
                    404: function () {
                        const data = {
                            route: [],
                            images: []
                        };
                        if (singular) {
                            updateTrack(data);
                        } else {
                            data["uniqueName"] = trackDisplayId;
                            formatsObject[trackDisplayId].lastUpdate = updateTrack(data);
                        }
                    }
                }
            }).always(function () {
                liveRequestCount -= 1;
                if (liveRequestCount === 0) {
                    PWGMap.addItem(trackGroup);
                    Object.keys(boatMarkerObject).forEach(function (name) {
                        if (singular || uniqueNames.indexOf(name) < 0) {
                            PWGMap.removeMarker(boatMarkerObject[name]);
                            delete boatMarkerObject[name];
                        }
                    });
                    if (!singular && boatMarker) {
                        PWGMap.removeMarker(boatMarker);
                        boatMarker = null;
                    }
                    if (recentreMap) {
                        if (boatMarker) {
                            PWGMap.setCentre(pwMap.Marker.getPosition(boatMarker));
                        } else if (boatMarkerObject && boatMarkerObject[uniqueNames[0]]) {
                            PWGMap.setCentre(pwMap.Marker.getPosition(boatMarkerObject[uniqueNames[0]]));
                        }
                    }
                    if (!singular) PWGMap.setupLegend(formatsObject);
                    trackDataLoadedCallbacks.fire(uniqueNames);
                }
            });
        });
        if (uniqueNames.length === 0) {
            PWGMap.addItem(trackGroup);
            Object.keys(boatMarkerObject).forEach(function (name) {
                PWGMap.removeMarker(boatMarkerObject[name]);
                delete boatMarkerObject[name];
            });
            if (boatMarker) {
                PWGMap.removeMarker(boatMarker);
                boatMarker = null;
            }
            //PWGMap.closePopup(); This causes popups on certain pages to auto close which is not desired - it also doesn't seem to be necessary for other use cases
            trackDataLoadedCallbacks.fire([]);
        }
        if (singular) {
            formatsObject = {};
            $j('.map-legend-wrapper').addClass('display-none');
        } else {
            Object.keys(formatsObject).forEach(function (name) {
                if (uniqueNames.indexOf(name) < 0) {
                    delete formatsObject[name];
                }
            });
            PWGMap.setupLegend(formatsObject);
        }
    }
    pollTimeout = setTimeout(poll, UPDATE_TIMEOUT);
}

const routesObject = {};

window.updateTrack = function updateTrack(data, firstTime, updateMap) {
    if (updateMap === undefined) {
        updateMap = true;
    }

    previousRouteIndex = null;
    previousImageIndex = null;

    const uniqueName = data["uniqueName"];
    if (uniqueName) {
        trackData = null;
        // Confusion would result from swapping that var from one boat to another.
        const
            track_duration = context['visible_durations'][data['uniqueName']],
            earliest_time = new Date(new Date() - 1000 * 60 * 60 * 24 * track_duration) / 1000;
        if (track_duration) {
            data.route = data.route.filter((value, index) => value.t > earliest_time);
        }
        // Possibly filter samples based on a time interval
        const startTime = context.startTimes?.[uniqueName];
        if (startTime) {
            while (data.route.length && data.route[0].t < startTime) {
                data.route.shift();
            }
        }
        const endTime = context.endTimes?.[uniqueName];
        if (endTime) {
            while (data.route.length && data.route[data.route.length - 1].t > endTime) {
                data.route.pop();
            }
        }

        if (context.show_only_one_sample) {
            // Used to implement "simple" display for cruising rallies... use only the latest
            // sample of those that have survived filtering thus far.
            data.route.splice(0, data.route.length - 1);
        }

        routesObject[uniqueName] = {
            "route": data.route,
            "src": "",
            "errors": "",
            "scale": "",
            "warnings": ""
        };
    } else {
        if (context['visible_duration']) {
            const earliest_time = new Date(new Date() - 1000 * 60 * 60 * 24 * context['visible_duration']) / 1000;
            data.route = data.route.filter((value, index) => value.t > earliest_time);
        }
        trackData = data;
    }

    if (context["showMap"]) {
        // determine time range
        if (data.route.length > 0) {
            timeMinimum = data.route[0].t;
            timeMaximum = Math.max(timeMaximum, data.route[data.route.length - 1].t);
        } else {
            timeMinimum = DEFAULT_TIME_MINIMUM;
            timeMaximum = DEFAULT_TIME_MAXIMUM;
        }
    } else {
        // determine time range
        if (data.images.length > 0) {
            timeMinimum = data.images[0];
            timeMaximum = data.images[data.images.length - 1];
        } else {
            timeMinimum = DEFAULT_TIME_MINIMUM;
            timeMaximum = DEFAULT_TIME_MAXIMUM;
        }
    }
    if (data.images.length > 0) {
        const
            minT = data.images[0],
            maxT = data.images[data.images.length - 1];
        timeMinimum = timeMinimum !== DEFAULT_TIME_MINIMUM ? Math.min(timeMinimum, minT) : minT;
        timeMaximum = timeMaximum !== DEFAULT_TIME_MAXIMUM ? Math.max(timeMaximum, maxT) : maxT;
    }
    let p;
    if (data.route.length > 0) {
        p = data.route[data.route.length - 1].p;
        if (!data['uniqueName'] || data['uniqueName'] === context['trackDisplayId']) {
            currentRouteIndex = data.route.length - 1;
            setValues(data.route[currentRouteIndex]);
        }
    } else {
        if ('uniqueName' in data) {
            p = null;
        } else {
            p = {lat: context["startLat"], lon: context["startLon"]};
        }
    }

    trackDuration = +$j('[name=trackDuration]:checked').val();
    let newRoute = clipRoute(data.route);

    // update map
    if (window.PWGMap.hasMap() && updateMap && data.route.length) {
        let routeList,
            formatting;
        if ("uniqueName" in data) {
            routeList = [routesObject[uniqueName], routesObject[uniqueName]];
            formatting = [{
                ...formatsObject[uniqueName],
                thickness: 3.0,
                color: 'black',
                opacity: 0.5,
                zIndex: pwMap.zLevels.routePath - 1,
                group: trackGroup
            }, {
                ...formatsObject[uniqueName],
                thickness: 1.5,
                opacity: 1.0,
                zIndex: pwMap.zLevels.routePath,
                group: trackGroup
            }];
        } else {
            routeList = [{"route": newRoute, "src": "", "errors": "", "scale": "", "warnings": ""},
                {"route": newRoute, "src": "", "errors": "", "scale": "", "warnings": ""}];
            formatting = [{
                name: context["trackCallsign"],
                thickness: 3.0,
                opacity: 0.5,
                color: 'black',
                lineStyle: 'solid',
                zIndex: pwMap.zLevels.routePath - 1,
                group: trackGroup
            }, {
                name: context["trackCallsign"],
                thickness: 1.5,
                opacity: 1.0,
                color: 'white',//context["trackLineColour"],
                lineStyle: 'solid',
                zIndex: pwMap.zLevels.routePath,
                group: trackGroup
            }];
            if (context['editable'] && PWGMap.map1._atlasMap) {
                const
                    chunkSize = 500,
                    route = data.route,
                    select = $j(".track-edit-controls select").empty();
                for (let index = 0; index < route.length; index += chunkSize) {
                    const startDate = new Date(route[index].t * 1000);
                    // Should the interface indicate the start or end range of the data you're about to edit?
                    //var endDate = new Date((route[index+chunkSize-1] || route[route.length-1]).t * 1000);
                    $j('<option>').data("samples", route.slice(index, index + chunkSize))
                        .text(startDate.toUTCString())
                        .prependTo(select);
                }
                select.toggleClass("display-none", route.length <= chunkSize)
                    .on('change', function () {
                        $j('.track-edit-controls button[name=edit]').trigger('click');
                    });
                $j(".track-edit-controls").removeClass("display-none");
            }
        }
        plotRoutes(routeList, formatting, true);
        PWGMap.addItem(trackGroup);
    }
    if ("uniqueName" in data) {
        if (p) {
            if (uniqueName in boatMarkerObject) {
                pwMap.Marker.setPosition(boatMarkerObject[uniqueName], p);
            } else {
                boatMarkerObject[uniqueName] = window.PWGMap.addCircleMarker({
                    lat: p.lat,
                    lon: p.lon,
                    fillColour: formatsObject[uniqueName].color || PWGMap.boatColour(uniqueName),
                    click: function (name) {
                        return function boatMarkerClicked() {
                            const route = routesObject[name].route;
                            window.PWGMap.showWindSampleOnMap(route[route.length - 1], formatsObject[name]);
                            // NOTE: this next line seems to be required for Google Maps API,
                            // but not for Atlas, I don't know why.
                            window.PWGMap.setCentre(route[route.length - 1].p);
                        };
                    }(uniqueName),
                    zIndex: pwMap.zLevels.routePosition,
                    anchorX: 6,
                    anchorY: 6,
                    width: 12,
                    height: 12
                });
            }
            if (!gotFirstBoatPosition) {
                gotFirstBoatPosition = true;
                window.Sentry?.setContext('boatMarker', boatMarker);
                PWGMap.setCentre(pwMap.Marker.getPosition(boatMarkerObject[uniqueName]));
            }
        }
    } else if (p) {
        if (boatMarker) {
            pwMap.Marker.setPosition(boatMarker, p);
        } else {
            if (PWGMap.map1._atlasMap) {
                boatMarker = window.PWGMap.addCircleMarker({
                    lat: p.lat,
                    lon: p.lon,
                    fillColour: 'red',
                    click: function boatMarkerClicked() {
                        if (currentRouteIndex >= 0 && currentRouteIndex < trackData.route.length) {
                            window.PWGMap.showWindSampleOnMap(trackData.route[currentRouteIndex]);
                        }
                    },
                    zIndex: pwMap.zLevels.routePosition,
                    anchorX: 6,
                    anchorY: 6,
                    width: 12,
                    height: 12
                });
            } else {
                boatMarker = window.PWGMap.addMarker({
                    lat: p.lat,
                    lon: p.lon,
                    className: boatMarkerClassName,
                    click: function boatMarkerClicked() {
                        if (currentRouteIndex >= 0 && currentRouteIndex < trackData.route.length) {
                            window.PWGMap.showWindSampleOnMap(trackData.route[currentRouteIndex]);
                        }
                    },
                    zIndex: pwMap.zLevels.routePosition,
                    anchorX: boatMarkerAnchorX,
                    anchorY: boatMarkerAnchorY,
                    width: boatMarkerWidth,
                    height: boatMarkerHeight
                });
            }
            if (!gotFirstBoatPosition) {
                gotFirstBoatPosition = true;
                window.Sentry?.setContext('boatMarker', boatMarker);
                PWGMap.setCentre(pwMap.Marker.getPosition(boatMarker));
            }
        }
    }

    function plotRoutes(routeList, formatting) {
        for (let j = 0; j < routeList.length && j < formatting.length; j++) {
            // nodes for markers
            const
                nodes = [],
                nodePositions = [],
                route = routeList[j].route,
                polylinesCoords = PWGMap.polylineCoordsFromRoute(route, undefined, undefined, undefined, nodes, nodePositions),
                group = formatting[j].group;

            for (let p = 0; p < polylinesCoords.length; p++) {
                const polylineClick = (function (nodes, routeIndex) {
                    return function (position, pathIndex) {
                        const pi = Math.floor(pathIndex);
                        if (pi >= 0 && pi < nodes.length - 1) {
                            const
                                t = pathIndex - pi,
                                node = PWGMap.interpolateNodes(nodes[pi], nodes[pi + 1], t);
                            PWGMap.showWindSampleOnMapCore(node, formatting);
                        }
                    };
                })(nodes, j);
                PWGMap.polylineWithFormat(polylinesCoords[p], formatting[j], pwMap.zLevels.routePath, group, polylineClick);
            }
        }
    }

    // Weather display should be updated for boat marker(s)
    $j('form.track-display-options').trigger('change');
    if (context["fullscreen"]) {
        // preload images
        let step = Math.max(1, trackData.images.length - 1);
        while (step > 0) {
            for (let i = 0; i < trackData.images.length; i += step) {
                // most recent first
                const
                    t = trackData.images[trackData.images.length - 1 - i],
                    k = '_' + t,
                    src = imageUrlFromTime(t);
                if (preload[k] === undefined) {
                    preload[k] = new Image();
                    preload[k].onload = (function (k) {
                        return function () {
                            preload[k] = null;
                        };
                    })(k);
                    setTimeout((function (k, src) {
                        return function () {
                            preload[k].src = src;
                        };
                    })(k, src), 1);
                }
            }
            step = Math.floor(step / 2);
        }
    }
    // NB: This awkward checking is because window.timeSlider will return a variable
    // called timeSlider in the global namespace, OR if that doesn't exist,
    // an element with id="timeSlider" in the document,
    // WHICH ACTUALLY DOES EXIST, but is no good to us!
    if (typeof timeSlider !== 'undefined' && timeSlider !== null && timeSlider.setRange) {
        timeSlider.setRange(timeMinimum, timeMaximum);
        if (!firstTime) {
            if (timeSlider.value < timeMinimum || timeSlider.value > timeMaximum) {
                isLive = canGoLive;
            }
        }
    }
    if (isLive) {
        handleTimeMaximum();
    }
    if (pwMap.Map === pwMap.Atlas.Map && pwMap.getDefaultTileLayerUrl().indexOf('satellite') !== -1) {
        window.PWGMap.map1._atlasMap.backgroundColour = 0x000000;
    }
    const
        now = new Date(),
        nowTimestamp = now.getTime() / 1000;
    let imageDelay = 1000000,
        routeDelay = 1000000,
        mapBannerText = '';

    if (data.route.length > 0) {
        routeDelay = (nowTimestamp - data.route[data.route.length - 1].t) / 60;
        mapBannerText = "Last Update " + formatLongTime(data.route[data.route.length - 1].t, window.PWGMap_options.utcOffset) + ' ' + formatTimezoneShort(window.PWGMap_options.utcOffset);
    }
    if (data.images.length > 0) {
        imageDelay = (nowTimestamp - trackData.images[data.images.length - 1]) / 60;
    }


    $j('#mapBannerText1,#mapBannerText2').html(mapBannerText);

    if (imageDelay == 1000000 && routeDelay == 1000000) {
        $j('#streamLabel').html('');
    } else {
        if (imageDelay <= 5) {
            $j('#streamLabel').html('live photos!');
        } else if (routeDelay <= 20) {
            $j('#streamLabel').html('tracking realtime, photos not live');
        } else {
            $j('#streamLabel').html('playback last race!');
        }
    }

    if (isPlaying) {
        stop();
        play();
    }
    return data.route.length > 0 ? data.route[data.route.length - 1].t : null;
};

function handleTimeMaximum() {
    setTimeout(handleTimeMaximum_, 1000);
}

function handleTimeMaximum_() {
    if (context["fullscreen"]) {
        if (context["displayAsHeader"]) {
            live();
        } else {
            const
                now = new Date(),
                nowTimestamp = now.getTime() / 1000,
                isOldData = (timeMaximum !== DEFAULT_TIME_MAXIMUM && nowTimestamp - timeMaximum > 20 * 60); // 20 mins
            if (isOldData) {
                rewind();
                play();
            } else {
                live();
            }
        }
    } else {
        live();
    }
}

window.stop = function stop() {
    isPlaying = false;
    if (animationHandle) {
        clearTimeout(animationHandle);
        animationHandle = null;
    }
    if (window.onStop) {
        onStop();
    }
};

window.rewind = function rewind() {
    stop();
    isLive = (timeMaximum === DEFAULT_TIME_MAXIMUM) && canGoLive;
    setTime(timeMinimum);
    if (window.onRewind) {
        onRewind();
    }
};

window.live = function live() {
    stop();
    isLive = canGoLive;
    setTime(timeMaximum);
    if (window.onLive) {
        onLive();
    }
};

window.play = function play() {
    stop();
    if (window.timeSlider?.value === timeMaximum) {
        setTime(timeMinimum);
    }
    play_();
    if (window.onPlay) {
        onPlay();
    }
};

function play_() {
    let stepTime;
    let nextTime;
    if (!window.timeSlider) {
        return;
    }
    if (!isPlaying) {
        isPlaying = true;
    }
    isLive = false;
    const oldTime = timeSlider.value;

    if (isContinuous) {
        nextTime = timeSlider.value + GLOBAL_continuousTimeStep * GLOBAL_continuousUpdateTime / 1000;
        stepTime = GLOBAL_continuousUpdateTime;
    } else {
        nextTime = timeSlider.value;
        stepTime = 1000;
    }

    let imageNextTime = null;
    for (let i = 1; i < trackData.images.length; i++) {
        if (trackData.images[i] > nextTime) {
            imageNextTime = trackData.images[i];
            break;
        }
    }
    let routeNextTime = null,
        nextRouteIndex = -1;
    for (let i = 1; i < trackData.route.length; i++) {
        if (trackData.route[i].t * 1 > nextTime) {
            routeNextTime = trackData.route[i].t * 1;
            nextRouteIndex = i;
            break;
        }
    }

    if (!isContinuous) {
        if (imageNextTime !== null && routeNextTime !== null) {
            nextTime = Math.max(nextTime, Math.min(imageNextTime, routeNextTime));
        } else if (imageNextTime !== null) {
            nextTime = Math.max(nextTime, imageNextTime);
        } else if (routeNextTime !== null) {
            nextTime = Math.max(nextTime, routeNextTime);
        }
    }

    setTime(nextTime);

    if (!isContinuous && nextRouteIndex != -1) {
        const p = trackData.route[nextRouteIndex].p;
        window.PWGMap.setCentre(p);
    }

    if (nextTime >= timeMaximum || nextTime == oldTime) {
        animationHandle = null;
        handleTimeMaximum();
    } else {
        animationHandle = setTimeout(play_, stepTime);
    }
}

window.sliderChanged = function sliderChanged(value) {
    isLive = (timeMaximum === DEFAULT_TIME_MAXIMUM || value >= timeMaximum) && canGoLive;
    stop();
    return setTime(value);
};

function setTime(value) {
    if (!window.timeSlider) {
        return;
    }
    value = Math.min(value, timeMaximum);
    if (window.timeSlider) {
        timeSlider.setValue(value);
    }
    const
        now = new Date(),
        nowTimestamp = now.getTime() / 1000;
    let routeIndex = trackData.route.length - 1;
    for (let i = 1; i < trackData.route.length; i++) {
        if (trackData.route[i].t * 1 > value) {
            routeIndex = i - 1;
            break;
        }
    }
    currentRouteIndex = routeIndex;
    let imageIndex = trackData.images.length - 1;
    for (let i = 1; i < trackData.images.length; i++) {
        if (trackData.images[i] > value) {
            imageIndex = i - 1;
            break;
        }
    }
    if (routeIndex !== previousRouteIndex || isContinuous) {
        previousRouteIndex = null;
        if (routeIndex >= 0) {
            setValues(trackData.route[routeIndex]);
            previousRouteIndex = routeIndex;
            if (boatMarker) {
                let p = trackData.route[routeIndex].p;
                if (isContinuous && routeIndex < trackData.route.length - 1) {
                    const
                        t0 = trackData.route[routeIndex].t,
                        t1 = trackData.route[routeIndex + 1].t,
                        t = (value - t0) / (t1 - t0),
                        p1 = trackData.route[routeIndex + 1].p;
                    let p0 = trackData.route[routeIndex].p;
                    if (Math.abs(p0.lon - p1.lon) > 180) {
                        p0 = {lat: p0.lat, lon: p0.lon};
                        if (p0.lon < p1.lon) {
                            p0.lon += 360;
                        } else {
                            p0.lon -= 360;
                        }
                    }

                    p = {
                        lat: p0.lat + t * (p1.lat - p0.lat),
                        lon: p0.lon + t * (p1.lon - p0.lon)
                    };
                }
                window.PWGMap.moveMarker(boatMarker, p);
                if (isLive) {
                    window.PWGMap.setCentre(p);
                }
            }
        } else {
            setValues(null);
        }
    }
    if (imageIndex !== previousImageIndex) {
        previousImageIndex = null;
        if (imageIndex >= 0) {
            showImage(imageIndex);
            previousImageIndex = imageIndex;
            const imageDelay = (nowTimestamp - trackData.images[imageIndex]) / 60;
            $j('#streamLabel').html(imageDelay > 5 ? '' : 'live picture');
        }
    }
}

function preloadCommonImage(src) {
    const img = new Image();
    img.src = src;
}


function formatTimezone(utcOffset) {
    const
        h = Math.floor(Math.abs(utcOffset)),
        m = Math.floor(Math.abs(utcOffset) * 60) % 60;
    return pgettext("UTC timezone", "GMT") +
        (utcOffset >= 0 ? '+' : '-') +
        (h < 10 ? '0' : '') + h +
        ':' +
        (m < 10 ? '0' : '') + m;
}

export function pw_trackDisplayPreInit() {
    console.warn("pw_trackDisplayPreInit() called!!!", document.readyState);

    // initialise the forecast update times section in sidebar
    if (window.PW_resetUpdatesWithResolution) {
        window.PW_resetUpdatesWithResolution(1);
    }

    window.PWGMap_options = {
        staticURL: context["STATIC_URL"],
        utcOffset: context["utcOffset"],
        utcOffsetLabel: formatTimezone(context["utcOffset"]),
        minimumResolution: 3,
        mapTypeId: 'hybrid', // For Google map
        styles: google_styles
    };

    if (window.Atlas) {
        // Note the url we get from this function depends on whether 'useSatellite' is in the query.
        const
            mapTileUrl = pwMap.getDefaultTileLayerUrl(),
            vectorTileUrl = pwMap.getDefaultVectorTileLayerUrl();
        window.PWGMap_options.baseLayers = [
            new Atlas.ItemLayer(),
            new Atlas.TileLayer({
                zIndex: 1000,
                tileSources: [
                    {
                        type: 'image',
                        url: mapTileUrl,
                        zoomSubset: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
                        zoomOffset: 0,
                        maxZoom: 16
                    }
                ]
            }),
            new Atlas.TileLayer({
                zIndex: 1000,
                tileSources: [
                    {type: 'vector', url: vectorTileUrl}
                ]
            }),
            new Atlas.TileLayer({
                visible: true,
                tileSources: [
                    {
                        type: 'grib', url: '/atlas/global/gribtile/{gribKey}-{subImage}.raw', layerName: 'Offshore',
                        zoomSubset: [0]
                    } // [3,4,5,6,7,8,9,10,11,12,13,14,15] }
                ]
            })
        ];
        window.PWGMap_options.baseLayerIndex = 0;
    }

    $j(document)
        .ajaxStart(function () {
            $j(showSpinner);
        })
        .ajaxStop(function () {
            $j(hideSpinner);
        });

    // Note that if we show the spinner before all our page CSS loads, it looks bad...
    // so it has visibility:hidden in the html, and we override that on page load. But page may have already loaded...
    // FIXME surely this is now over-engineered, can't we just use style="display:none" in the template?
    const correctSpinnerVisibility = () => document.readyState === 'complete' && $j('#id_spinnerFrame').css('visibility', '');
    $j(document).on('readystatechange', correctSpinnerVisibility);
    correctSpinnerVisibility();

    // Begin weather display logic

    window.lookupGribKeyForLayerName = function (layerName, display) {
        console.log("lookupGribKeyForLayerName " + layerName);
        const gribSource = PWGMap.map1.gribSource || "PWG";
        let startPoint;
        if (boatMarkerObject && Object.keys(boatMarkerObject).length) {
            const marker = boatMarkerObject[Object.keys(boatMarkerObject)[0]];
            startPoint = {
                lat: Math.round(marker.lat),
                lon: Math.round(marker.lon)
            };
        } else if (boatMarker) {
            startPoint = {
                lat: Math.round(boatMarker.lat),
                lon: Math.round(boatMarker.lon)
            };
        } else {
            console.log('lookupGribKeyForLayerName: no boatMarker for reference');
            return null;
        }
        const b = {
            s: startPoint.lat - 20,
            n: startPoint.lat + 20,
            w: startPoint.lon - 20,
            e: startPoint.lon + 20
        };
        return pwLookupGlobalGribKey(layerName, display, gribSource, startPoint, b);
    };
    window.getGribIndexEntry = function (gribKey) {
        return getGlobalGribIndexEntry(gribKey);
    };

    if (window.Atlas) {
        window.Atlas.onTextureLoaderSuccess = function (loader, url, meta) {
            if (meta && meta.ts && meta.ts.length) {
                // Set GRIB display time to now, or the last time in the GRIB if that is in the past
                let time = Math.min(meta.ts[meta.ts.length - 1], Math.floor(Date.now() / 1000));
                PWGMap.map1.setDisplayTime(time);
            }
        };
    }
    // End weather display logic

    $j(".map-legend-smartphone-button").on("click", function (event) {
        event.stopPropagation();
        const container = $j('.track-display-right-column');
        container.toggleClass('track-display-right-column-open');
        // This is a bit of a hack... when we 'dismiss' the expanded legend, the re-oriented container element
        // gets a zero scroll, but that feels wrong. Note element.scrollIntoView() has some nice features that
        // haven't really landed yet, so we do the work ourselves
        if (!container.hasClass("track-display-right-column-open")) {
            const details = $j('.track-update-details').filter(':visible');
            $j('.track-display-right-column')[0].scrollLeft = details.length && details.outerWidth();
        }
    });
    $j('.track-display-options-toggle,' +
        '.track-display-options-open,' +
        '.track-display-options-close').on('click', function () {
        $j('.track-display-options').toggleClass('track-display-options-closed');
    });
    $j('form.track-display-options').on('change', function (event) {
        const
            form = this,
            mapModeControl = $j('[name=mapMode]:checked', form); // Detect change of map mode, by seeing whether the control now checked was originally checked
        if (!mapModeControl.attr("checked")) {
            // Implement the change using a URL shorthand
            // Atlas map weather params are lost if you switch to satellite view, but that makes the URL clean.
            let mapMode = mapModeControl.val();
            window.location.search = (mapMode === 'useAtlas' ? '' : '?' + mapMode);
            return;
        }
        // And if we are on a google map, the rest doesn't matter.
        // We hide controls on the initial call to avoid confusion.
        if (mapModeControl.val() == "useGoogle") {
            $j(form).addClass('track-display-options-google');
            return;
        }
        if (event.originalEvent) {
            // Doesn't run on first invocation.
            // So that folks can see the state changes in the url and bookmark them...
            window.history.replaceState({}, document.title, '?' + $j(form).serialize());
        }
        const
            windSymbol = $j('[name=windSymbol]:checked', form).val(),
            gribSource = $j('[name=weatherSource]:checked', form).val();
        PWGMap_addInitListener(function () {
            PWGMap.map1.gribSource = (windSymbol === "OFF") ? "OFF" : gribSource;
            const map = PWGMap.map1._atlasMap;
            if (map) {
                if (windSymbol !== "OFF") {
                    map.gribWindSymbol = windSymbol;
                }
                console.log("Trying to cause map to load GRIB tiles!");
                map.removeGribTiles();
                map.redraw();
            }
        });
    });
    $j('form.track-edit-controls button').on('click', function () {
        if (this.name === "edit") {
            startEditing();
            resetBounds();
        } else {
            window.location.reload();
        }
    });
    const
        blog_url = $j(document.body).data('blog-url'),
        isMobile = window.matchMedia && matchMedia("(hover:none)").matches,
        hide_blog = isMobile && context['cruisingRallyName'];
    if (blog_url && !hide_blog) {
        $j('.blog-post-outer-wrapper.blog-post-add').removeClass("display-none").on('click', composeBlogPost);
        getBlogFeed(blog_url);
    }
    $j('#tracking-groups')
        .on("keyup keydown", function (event) {
            // If these events propagate to the document, atlas might handle them and then prevent the default!
            event.stopPropagation();
        });
    $j('.share-track-url')
        .on('click', function (event) {
            let url = new URL(`/tracking/display/${context['trackDisplayId']}/`, document.baseURI);
            const shareData = {
                title: context['boatName'] + " PredictWind Tracking Page",
                text: "Check out " + context['boatName'] + "'s tracking page.",
                url: url.toString()
            };
            if (navigator.canShare?.(shareData)) {
                $j('.webshare-unsupported-wrapper').addClass('display-none');
                navigator.share(shareData)
                    .catch(reason => {
                        if (reason.name === 'AbortError') {
                            // User cancelled; suppress this error so that we don't see it in Sentry.
                            console.log(reason);
                        } else {
                            throw reason;
                        }
                    });
            } else {
                $j('.webshare-unsupported-wrapper button').off('click');
                $j('.copy-to-clipboard p.share-url').text(shareData.url);
                $j('.webshare-unsupported-wrapper').removeClass('display-none');
                $j('.webshare-unsupported-wrapper button').on('click', () => writeShareUrlToClipboard(shareData));
            }
        });
    $j('.webshare-unsupported-close')
        .on('click', function (event) {
            $j('.webshare-unsupported-wrapper').addClass('display-none');
        });
    if (context['trackDisplayId'] && context['trackDisplayId'] !== 'GenericBoatNameClass') {
        if (isSmartPhoneBrowser || isSmartPhone) {
            $j('.mobile-share-track-wrapper').addClass('active');
        } else {
            $j('.share-track-url-wrapper').removeClass("display-none");
        }
    }
}

let timeoutId = null;
async function writeShareUrlToClipboard(shareData) {
    try {
        await navigator.clipboard.writeText(shareData.url);
        $j('.webshare-unsupported-wrapper button').text(copied_text);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            $j('.webshare-unsupported-wrapper button').text(copy_to_clipboard_text);
        }, 5000);
    } catch (error) {
        console.log(error.message);
    }

}

export function init() {
    console.warn("init() called!!!", document.readyState);
    if (pwMap.Map === pwMap.Atlas.Map) {
        let gl,
            canvas = document.createElement('canvas');
        try {
            gl = canvas.getContext("webgl");
        } catch (x) {
            gl = null;
        }
        if (gl == null) {
            try {
                gl = canvas.getContext("experimental-webgl");
            } catch (x) {
                gl = null;
            }
        }
        if (gl == null) {
            setTimeout(function () {
                window.location.search = '?mapMode=useGoogle';
            }, 1000);
            window.Sentry?.captureMessage("No WebGL support on track display page.");
            return;
        }
    }
    pw_trackDisplayPreInit();

    window.PWGMap = new PWGMap_impl(window.PWGMap_options);
    if (window.TouchSlider) {
        window.timeSlider = new TouchSlider('slider', 0, 1, sliderChanged);
        timeSlider.setValue(isLive ? 1 : 0);
    }
    PWGMap.initialiseMap({
        ...window.PWGMap_options,
        lat: context['startLat'] || 0,
        lon: context['startLon'] || 0,
        zoom: defaultZoom
    });
    $j(".track-display-right-column").on("click", function (event) {
        if (event.currentTarget === this) {
            $j('.track-display-right-column').removeClass('track-display-right-column-open');
        }
    });
    $j(window).on('resize', function () {
        if (typeof timeSlider !== 'undefined') timeSlider?.setValue(timeSlider.value);
    });
    if (PWGMap.map1._googleMap) {
        // Tweak to Google Map controls
        window.PWGMap.map1._googleMap.setOptions({
            fullscreenControl: false
        });
        window.PWGMap.map1._googleMap.controls[google.maps.ControlPosition.LEFT_TOP].push(
            $j('.console').detach().show()[0]
        );
        $j('.track-display-right-column').css({marginBottom: '15px'});
        $j('.mobile-share-track-wrapper').css({'bottom': '138px'});
        // Hide google maps zoom control in "mobile mode" according to appropriate media query, because
        // there's no good CSS selector to find it :(
        const mediaQueryList = window.matchMedia("(hover:none), (max-width: 799px)");
        window.PWGMap.map1._googleMap.setOptions({
            zoomControl: !mediaQueryList.matches
        });
        $j(window).on('resize', function () {
            window.PWGMap.map1._googleMap.setOptions({
                zoomControl: !mediaQueryList.matches
            });
        });
    }
    poll();
    preloadCommonImage(context["STATIC_URL"] + 'images/tvf_sliderbg2.png');
}

// Allow some control to be exercised from other scripts.
window.pwGPSTrackingContext = context;
export const pwGPSTrackingContext = context;
window.pwPollGPSTrack = function (recentreMap) {
    clearTimeout(pollTimeout);
    poll(recentreMap);
    return pollTimeout;
};


function getBlogFeed(blog_url) {
    return $j.ajax(blog_url, {cache: false})
        .done(function (data) {
            const byExternalId = context['byExternalId'];
            if (byExternalId) {
                data['posts'].forEach(function (post) {
                    post.uniqueName = byExternalId[post['external_id']];
                    post.name = context["trackNames"][post.uniqueName];
                });
            }

            PWGMap_addInitListener(function () {
                // FIXME: handing the click handler through is weird?
                PWGMap.displayBlogPostSnippets(data['posts'], expandBlogPost);
                // Correct urls to Discourse uploads that lack a hostname.
                // Note that Discourse fixes various things about the cooked HTML
                // of a post in an asynchronous job ... so posts are broken for
                // a few seconds after being created or edited :((
                const discourse_url = $j(document.body).data('discourse-url');
                $j('.track-display-right-column img[src^="/uploads"]').each(function () {
                    this.src = discourse_url + $j(this).attr('src');
                });

                blogFeedLoadedCallbacks.fire(data['posts']);
            });
        });
}

function expandBlogPost() {
    const
        article = $j(this).closest('.blog-post-wrapper').find("article"),
        blog_url = $j(document.body).data('blog-url');
    let buttons = [];
    if (blog_url && $j('.blog-post-add').length) {
        buttons = [
            $j('<button>', {"class": "blog-post-edit"})
                .text(gettext('Edit Post'))
                .on('click', function () {
                    closeBlogPost();
                    composeBlogPost();
                    const post = {...article.data()};
                    const d = new Date(post["created_at"]);
                    post['created_at_date'] = (d.getFullYear()
                        + '-' + pad2(d.getMonth() + 1)
                        + '-' + pad2(d.getDate()));
                    post['created_at_time'] = pad2(d.getHours()) + ':' + pad2(d.getMinutes());
                    const form = $j(".modal .blog-post-compose")[0];
                    $j.each(post, function (key, value) {
                        if (form.elements[key]) {
                            form.elements[key].value = value;
                        }
                    });
                }),
            $j('<button>', {"class": "blog-post-delete"})
                .text(gettext('Delete Post'))
                .on('click', function () {
                    if (!window.confirm(gettext("Delete this blog post?"))) {
                        return;
                    }
                    this.disabled = true;
                    $j.ajax(article.data('url'), {
                        method: "delete",
                        headers: {'X-CSRFToken': $j("[name=csrfmiddlewaretoken]").val()}
                    }).done(() => {
                        closeBlogPost();
                        getBlogFeed(blog_url);
                    }).fail(() => {
                        alert(
                            gettext("There was an error deleting your post. Please try again later.")
                        );
                        this.disabled = false;
                    })
                })
        ];
    }
    const
        post = article.data(),
        new_modal = $j('<div>', {"class": "modal"})
            .append(
                $j('<div>', {"class": "modal-click-target"}).on('click', closeBlogPost),
                $j('<div>', {"class": "blog-post-close-wrap"}).append(
                    $j('<div>', {"class": "blog-post-close"}).on('click', closeBlogPost),
                    $j('<div>', {"class": "blog-post-show-on-map", title: gettext("Show on map")}).hide()
                ),
                article.clone()
                    .find(".track-display-blog-post-body")  // img elements are broken so parse the cooked HTML again
                    .html(post['cooked'])  // FIXME this patchup won't be needed with img loading="lazy" strategy
                    .end()  // see renderBlogPostSnippet in routing.js
                    .append(buttons),
                $j('<div>', {"class": "modal-after"})
            )
            .on("keyup keydown", function (event) {
                // If these events propagate to the document, atlas might handle them and then prevent the default!
                event.stopPropagation();
            })
            .appendTo("body");

    // Use d3Array bisector to sync a post with a track sample... but we may have blog posts
    // before we have any track data
    trackDataLoadedCallbacks.add(function () {
        const
            post = {...article.data()},
            timestamp = Date.parse(post.created_at) / 1000;
        post.sample = findSampleAtTimestamp(timestamp, post.uniqueName);
        if (post.sample) {
            $j('.blog-post-show-on-map', new_modal)
                .on('click', function () {
                    closeBlogPost();
                    showTrackSampleForBlogPost(post);
                })
                .text(pwMap.utils.formatCoords(post.sample.p.lat, post.sample.p.lon))
                .show();
        }
    });
}

// will return undefined if no sample is found covering that timestamp
function findSampleAtTimestamp(timestamp, uniqueName) {
    const bisectTimestamp = bisector(function (sample) {
        return sample.t;
    }).right;
    if ((!context.trackDisplayIds || !routesObject[uniqueName]) && !trackData) {
        return null;
    }
    const
        route = context.trackDisplayIds && routesObject[uniqueName] ? routesObject[uniqueName].route : trackData.route,
        index = bisectTimestamp(route, timestamp) - 1;
    return route[index];
}

function showTrackSampleForBlogPost(post) {
    console.log('showTrackSampleForBlogPost()');
    if (post.sample) {
        const sampleFormat = {
            name: post.boatName
        };
        if (post.uniqueName) {
            sampleFormat.color = PWGMap.boatColour(post.uniqueName);
        }
        PWGMap.setCentre(post.sample.p);
        PWGMap.showBlogPostOnMap(post, sampleFormat, expandBlogPost);
        $j('#toggle-blog-button:visible').addClass('hide-blogs');
    }
}

function closeBlogPost() {
    $j(".modal").remove();
}

// Composing new blog post
function composeBlogPost() {
    const form = $j(".blog-post-wrapper.blog-post-add").find('form').clone().removeClass("display-none");
    // IE 11 does a weird thing... and copies the placeholder into the textarea. Odd.
    $j('textarea', form).val('');

    // You can leave date and time blank, and get a good default, but this isn't obvious,
    // and it's easier to fill them in than to explain.
    const now = new Date();
    $j('[name=created_at_tzoffset]', form).val(now.getTimezoneOffset());
    $j('[name=created_at_date]', form).val(
        now.getFullYear()
        + '-' + pad2(now.getMonth() + 1)
        + '-' + pad2(now.getDate())
    );
    $j('[name=created_at_time]', form).val(
        pad2(now.getHours()) + ':' + pad2(now.getMinutes())
    );

    $j('<div>', {"class": "modal blog-post-compose-wrap"})
        .append(
            $j('<div>', {"class": "blog-post-close-wrap"})
                .append($j('<div>', {"class": "blog-post-close-title"})
                    .text(
                        context["trackDisplayId"] + ": " + gettext("Add a Post")
                    )
                )
                .append(
                    $j('<div>', {"class": "blog-post-close"}).on('click', closeBlogPost)
                )
        )
        .append(form)
        .append(
            $j('<div>', {"class": "modal-after"})
        )
        .appendTo("body");
    $j('input,textarea', form).on("keydown change input paste", function (event) {
        if (event.type == "keydown") {
            // Avoid each keypress hitting this handler twice... only continue on backspace/delete
            // (which do not appear to produce an input event)
            if (event.which != 8 && event.which != 46) {
                return;
            }
        }
        this.setCustomValidity('');
        schedulePreview();
    });
    $j('.toggle-preview', form).on('click', function (event) {
        event.preventDefault();
        $j('.toggle-preview,.preview,.blog-post-preview', form).toggleClass('display-none');
        schedulePreview(10);
    });
    const
        previewRootProperties = '-ms-user-select: none; -webkit-user-select: none; user-select: none;\n' + 'color: Black;\n',
        previewStyles = 'img{max-width: 100%}';                 // iframes behave weirdly in Mobile Safari - they ALWAYS take on their content height; and if you wrap
    // them in <div style="overflow: scroll"></div> that fails to allow interactive scrolling.
    // We can avoid using an iframe if we have Shadow DOM (v1)
    // which covers Mobile Safari 10+. So Mobile Safari 9 remains broken, I have no fix :(
    if (document.body.attachShadow) {
        $j('iframe.blog-post-preview', form).remove();
        const shadow = $j('div.blog-post-preview', form)[0].attachShadow({mode: 'open'}); // Allows JS access inside
        shadow.innerHTML = (
            '<style>\n'
            + '#root { all: initial;' + previewRootProperties + '}\n'
            + previewStyles
            + '</style>\n'
            + '<div id="root"></div>\n'
        );
        // There's a trick here to get the user-agent default font rather than the initial font...
        // relies on the fact that we have set our own font on <body>, not <html>
        $j('#root', shadow).css({
            display: 'block',
            font: getComputedStyle(document.documentElement).font
        });
    } else {
        $j('div.blog-post-preview', form).remove();
        $j('iframe', form)[0].contentDocument.write(
            '<!doctype html>\n' +
            '<html lang="en">\n' +
            '  <head>\n' +
            '    <title></title>\n' +
            '    <style>\n' +
            '    body{\n' +
            '      ' + previewRootProperties + '\n' +
            '    }\n' +
            '    ${previewStyles}\n' +
            '    </style>\n' +
            '  </head>\n' +
            '  <body></body>\n' +
            '</html>'
        );
    }
    $j('.blog-post-compose-cancel', form).on('click', closeBlogPost);
    $j('.blog-post-compose-save', form).on('click', async function (event) {
        event.preventDefault();
        const
            form = this.form,
            valid = reportValidity(form);
        if (!valid) {
            return;
        }
        this.disabled = true;
        form.elements.raw.style.visibility = "hidden";
        $j(form.elements).each(function () {
            this.setCustomValidity('');
        });
        const photo_files = new FormData(form).getAll('photo');
        if (photo_files.length < 1) {
            photo_files.push(null);
        }
        let jqXHR;
        try {
            console.log(`Trying to upload in ${photo_files.length} pieces`);
            for (const photo_file of photo_files) {
                const fd = new FormData(form);
                if (photo_file) {
                    // Include only one file in the upload data
                    fd.set('photo', photo_file, photo_file.name);
                    console.assert(fd.getAll('photo').length === 1)
                    console.log("Uploading:", photo_file.name);
                } else {
                    console.log("No file to upload.");
                }
                // FIXME The semantics of using a jqXHR as a Promise are subtle and not really documented,
                // and I'm not sure I've understood them! So perhaps better to use fetch().
                // The main differences are:
                // - fetch promise resolves to a Response object which you get the content from asynchronously
                // - fetch() never treats any HTTP response code as an error, so one needs to check for response.ok
                // Also for this to work with jQuery, the magical incantations of contentType, enctype, and processData
                // must be exactly right!
                jqXHR = $j.ajax({
                    url: form.action,
                    method: form.method,
                    contentType: false,
                    enctype: form.enctype,
                    data: fd,
                    processData: false
                });
                // The trick is to grab the new 'raw' field from the response because it has been rewritten to
                // include a link to the uploaded photo.
                // Also, if this was a new post, we need the topic_id to allow amending it.
                console.log("Waiting on one request...");
                const response = await jqXHR;
                form.elements.topic_id.value = response.topic_id;
                form.elements.raw.value = response.raw;
                // Now we can go around again if we aren't finished.
            }
        } catch {
            let msg = '',
                data = jqXHR.responseJSON;
            if (data && data.errors && data.errors.length) {
                msg = gettext("Your blog post needs some improvement.\n\n");
                msg += data.errors.join('\n\n')
            } else if (data && data.errors && Object.keys(data).length) {
                $j.each(data.errors, function (key, errorlist) {
                    const errorstr = $j.map(errorlist, function (o) {
                        return o["message"];
                    }).join('\n');
                    if (key === context["NON_FIELD_ERRORS"]) {
                        msg += errorstr;
                    } else if (!form.elements[key]) {
                        msg += errorstr + " (" + key + ")";
                    } else {
                        form.elements[key].setCustomValidity(errorstr);
                    }
                    if (msg) {
                        msg = gettext("Please fix the following errors:\n\n") + msg;
                    }
                });
            } else {
                msg = gettext("There was an error saving your post. Please try again later.");
            }
            $j('.non-field-errors', form).text(msg);
            this.disabled = false;
            form.elements.raw.style.visibility = null;
            reportValidity(form);
            return;
        }
        closeBlogPost();
        const blog_url = $j(document.body).data('blog-url');
        getBlogFeed(blog_url);
    });
    $j('[name=title]', form).trigger('focus');

    function reportValidity(form) {
        if ('reportValidity' in form) {
            return form.reportValidity();
        }
        const valid = form.checkValidity();
        $j(form).toggleClass('blog-post-compose-invalid', !valid);
        if (!valid) {
            $j(':invalid', form).first().trigger('focus');
        }
        return valid;
    }
}

let previewTimeout;

function schedulePreview(after) {
    clearTimeout(previewTimeout);
    previewTimeout = setTimeout(preview, after !== undefined ? after : 2000);
}

function preview() {
    const el = $j('.modal .blog-post-preview');
    if (el.is(":visible")) {
        console.log("Make a preview!");
        const
            md = window.markdownit('commonmark'),
            title = $j('.modal .blog-post-compose [name=title]').val(),
            text = $j('.modal .blog-post-compose [name=raw]').val();
        let preview;
        if (el.is('iframe')) {
            preview = $j('body', el[0].contentDocument);
        } else {
            preview = $j('div', el[0].shadowRoot).first();
        }
        preview.html(md.render(text));
        preview.prepend(
            $j('<h1>').text(title)
        );
        // Add a photo if present.
        const reader = new FileReader();
        $j(reader).on('load', function () {
            const dataUrl = this.result;
            $j('<img>')
                .prop('width', '570')
                .on('load', function () {
                    if (this.naturalWidth < this.width) {
                        this.width = this.naturalWidth;
                    }
                })
                .prop('src', dataUrl)
                .appendTo(preview);
        });
        const
            file_control = $j('.modal .blog-post-compose [name=photo]')[0],
            file = file_control && file_control.files[0];
        if (file) {
            reader.readAsDataURL(file);
        }
    }
}

// Editing a track
function startEditing() {
    clearTimeout(pollTimeout);
    $j('.track-display-options [value=OFF]').trigger('click');
    $j('.track-display-top-left-controls,.track-display-options').addClass("display-none");
    const option = $j('.track-edit-controls option:selected');
    window.PWGMap.clearRoutes();
    PWGMap.showWindSampleOnMapCallback = editWindSampleCallback;
    window.PWGMap.plotRoutes(
        [{"route": option.data("samples"), "src": "", "errors": "", "scale": "", "warnings": ""}],
        [{
            name: context["trackCallsign"],
            thickness: 2.5,
            opacity: 1.0,
            color: 'white',//context["trackLineColour"],
            lineStyle: 'solid',
            zIndex: pwMap.zLevels.routePath,
            showMarkers: true
        }],
        false
    );
    $j('.track-edit-controls').addClass("editing");
}

function resetBounds() {
    if (PWGMap.plotRouteOverlays.length) {
        var b = null,
            union = pwMap.utils.boundsUnion;
        PWGMap.plotRouteOverlays.forEach(function (item) {
            b = union(b, item.bounds);
        });
        PWGMap.setBounds(b);
    }
}

function editWindSampleCallback(sample) {
    // Note the click handler seems to be called multiple times so be careful...
    let input = $j('.atlas.popup input');
    if (input.length) {
        return;
    }
    const coordsB = $j('.atlas.popup b:last-of-type');
    input = $j('<input>', {value: pwMap.utils.formatCoords(sample.p)});
    coordsB.replaceWith(input);
    $j('<button>', {type: "button", name: "save-sample", disabled: "disabled"})
        .text(gettext('Save'))
        .appendTo(input.parent())
        .on('click', function () {
            this.disabled = true;
            const position = $j(this).data('position');
            console.log("You wish to save:", position);
            // Contact server; wait for success before doing side effects
            $j.ajax($j(document.body).data('updateSampleUrl'), {
                method: 'post',
                data: {
                    ...sample,
                    p: position,
                    csrfmiddlewaretoken: $j("[name=csrfmiddlewaretoken]").val()
                }
            }).done(() => {
                Object.assign(sample.p, position);
                $j(".atlas.popup .atlas.close").trigger('click');
                startEditing();
            }).fail(thing => {
                console.log(thing);
                this.disabled = false;
                alert(gettext("There was an error. Please try again later."));
            });
        });
    $j('<button>', {type: "button", name: "delete-sample"})
        .text(gettext('Delete'))
        .appendTo(input.parent())
        .on('click', function () {
            this.disabled = true;
            const
                route = $j(".track-edit-controls option:selected").data("samples"),
                i = route.indexOf(sample);
            if (i < 0) {
                console.error("Nothing to delete???");
                $j(".atlas.popup .atlas.close").trigger('click');
            } else {
                $j.ajax($j(document.body).data('deleteSampleUrl'), {
                    method: 'post',
                    data: {...sample, csrfmiddlewaretoken: $j("[name=csrfmiddlewaretoken]").val()}
                }).done(() => {
                    route.splice(i, 1);
                    $j(".atlas.popup .atlas.close").trigger('click');
                    startEditing();
                }).fail(thing => {
                    console.log(thing);
                    this.disabled = false;
                    alert(gettext("There was an error. Please try again later."));
                });
            }
        });
    input.on('change', function () {
        try {
            console.log("Parsing string: " + this.value);
            const p = pwMap.utils.parseCoords(this.value);
            console.log(p);
            if (!isNaN(p.lat) && !isNaN(p.lon)) {
                console.log('Valid string: ' + this.value);
                console.log('Existing position:');
                console.log(this.defaultValue);

                if (pwMap.utils.formatCoords(p) !== this.defaultValue) {
                    console.log("Successful new position");
                    console.log(p);
                    $j('[name=save-sample]')
                        .prop('disabled', false)
                        .data('position', p);
                    $j('[name=delete-sample]')
                        .prop('disabled', true);
                } else {
                    $j('[name=delete-sample]')
                        .prop('disabled', false);
                }
                // Else, position is the already-saved value. Either way, success and finished
                this.style.color = '';
                return;
            }
            // Fall through for NaN values
        } catch (e) {
            // Fall through for parsing throwing an exception
            console.error(e);
        }
        // Could not parse something sensible
        console.log("Invalid string: " + this.value);
        this.style.color = 'Red';
        $j('[name=save-sample]')
            .prop('disabled', true)
            .data('position', undefined);
        $j('[name=delete-sample]')
            .prop('disabled', true);
    }).keyup(function (event) {
        if (event.which === 27) {
            // Escape key
            console.log("Reset to default value!");
            this.value = this.defaultValue;
            this.style.color = undefined;
        } else {
            setTimeout(function () {
                console.log("Inside timeout. Value: " + input.val());
                input.trigger('change');
            })
        }
    });
}

function plotBlogPosts(posts) {
    for (let i = 0; i < posts.length; i++) {
        const
            post = posts[i],
            timestamp = Date.parse(post.created_at) / 1000;
        post.sample = findSampleAtTimestamp(timestamp, post.uniqueName); // find the sample closest to when the post was made

        if (!post.sample) {
            continue; // blog post was made outside the range of our route, so don't show it
        }

        function blogPostMarkerClicked(post) {
            return function () {
                showTrackSampleForBlogPost(post);
            }
        }

        if (PWGMap.map1._atlasMap) {
            window.PWGMap.addCircleMarker({
                lat: post.sample.p.lat,
                lon: post.sample.p.lon,
                fillColour: 'white',
                click: blogPostMarkerClicked(post),
                zIndex: pwMap.zLevels.routePosition,
                anchorX: 4,
                anchorY: 4,
                width: 8,
                height: 8
            });
        } else {
            window.PWGMap.addMarker({
                lat: post.sample.p.lat,
                lon: post.sample.p.lon,
                className: blogPostMarkerClassName,
                click: blogPostMarkerClicked(post),
                zIndex: pwMap.zLevels.routePosition,
                anchorX: blogPostMarkerAnchorX,
                anchorY: blogPostMarkerAnchorY,
                width: blogPostMarkerWidth,
                height: blogPostMarkerHeight
            });
        }
    }
}


